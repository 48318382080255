<template>
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false" id="localeSwitch">
      {{ $i18n.locale.toUpperCase() }}
    </a>
    <ul class="dropdown-menu" aria-labelledby="localeSwitch">
      <li v-for="locale in locales" :key="locale.code" :value="locale.code" v-on:click="changeLocale(locale.code)">
        <a class="dropdown-item" href="#">{{ locale.code.toUpperCase() }}</a>
      </li>
    </ul>
  </li>
</template>

<script>
import { getSupportedLocales } from '@/utils/locale'
import { I18n as amplify_i18n } from 'aws-amplify';


export default {
  name: 'LocaleSwitch',
  data: () => ({
    locales: getSupportedLocales(),
  }),
  methods: {
    changeLocale(locale) {
      console.log(locale)
      this.$i18n.locale = locale
      amplify_i18n.setLanguage(locale)
    }
  }
}
</script>

<style scoped></style>
