import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        user: null
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        },
    },
    getters: {
        isLoggedIn: (state) => {
            return state.user !== null
        },
        user: (state, getters) => {
            if(getters.isLoggedIn)
                return state.user.attributes
            else
                return null
        }
    },
    actions: {
    },
    modules: {
    },
    plugins: [createPersistedState()],
})
