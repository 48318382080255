import { createRouter, createWebHistory } from 'vue-router'
import { Hub } from "aws-amplify";
import store from '../store/user.js'
import { refreshAndGetUser } from "@/utils/cognito";

Hub.listen('auth', async ({ payload }) => {
  const next = router.currentRoute.value.query.nextUrl

  switch (payload.event) {
    case 'signIn':
      await refreshAndGetUser()
      await router.push({path: next ? next : '/application'})
      break
    case 'signOut':
      store.commit('setUser', null)
      await router.push({path: '/login', query: { nextUrl: router.currentRoute.value.fullPath }})
      break
  }
})

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue')
  },
  {
    path: '/products/dentalaide',
    name: 'Dentalaide',
    component: () => import(/* webpackChunkName: "product" */ '../views/ProductView.vue')
  },
  {
    path: '/team',
    name: 'team',
    component: () => import(/* webpackChunkName: "product" */ '../views/TeamView.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/UserProfileView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/AuthView.vue')
  },
  {
    path: '/application',
    name: 'applicaton',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "application" */ '../views/ApplicationView.vue')
  },
  {
    path: '/canvas',
    name: 'canvas',
    meta: { requiresAuth: false },
    component: () => import(/* webpackChunkName: "canvas" */ '../views/NewCanvasView.vue')
  },
  // {
  //   path: '/annotate',
  //   name: 'annotate',
  //   meta: { requiresAuth: true },
  //   component: () => import(/* webpackChunkName: "application" */ '../views/AnnotationView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = await refreshAndGetUser()
    if (!user) {
      return next({
        path: '/login',
        query: {
          nextUrl: to.fullPath,
        }
      })
    }
  }
  return next()
});

export default router
