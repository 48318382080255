import { Auth } from "aws-amplify"

const amplify_config = {
    Auth: {
        identityPoolId: process.env.VUE_APP_COGNITO_IDPID,
        region: process.env.VUE_APP_COGNITO_REGION,
        userPoolId: process.env.VUE_APP_COGNITO_UPID,
        userPoolWebClientId: process.env.VUE_APP_COGNITO_UPWCID,
    },
    API: {
        endpoints: [
            {
                name: "TotIA API",
                endpoint: process.env.VUE_APP_TOTIA_API_URL,
                custom_header: async () => {
                    return { Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()}
                  }
            },
        ]
    }
}

export default amplify_config

