export const locale = {
    es: 'Español',
    en: 'English',
}

function getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: false }
    const opt = { ...defaultOptions, ...options }
    const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language
    if (!navigatorLocale) {
        return undefined
    }
    return opt.countryCodeOnly ? navigatorLocale.trim().split(/[-_]/)[0] : navigatorLocale.trim()
}

export function getStartingLocale() {
    const browserLocale = getBrowserLocale({ countryCodeOnly: true })
    if (Object.keys(locale).includes(browserLocale)) {
        return browserLocale
    } else {
        return process.env.VUE_APP_I18N_LOCALE || 'es'
    }
}

export function loadLocaleMessages() {
    const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

export function getSupportedLocales() {
    let annotatedLocales = []
    for (const code of Object.keys(locale)) {
        annotatedLocales.push({
            code,
            name: locale[code],
        })
    }
    return annotatedLocales
}
