<template>
  <div>
    <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light d-flex py-4 px-lg-5" ref="header">
      <div class="container-fluid">
        <div>
          <a class="navbar-brand logo" href="/"><img :src="require('@/assets/img/logo/totia.svg')" alt="Logo"/></a>

          <button class="navbar-toggler float-end" type="button" data-bs-toggle="collapse" data-bs-target="#nav-collapse" aria-controls="nav-collapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>

        <div class="collapse navbar-collapse" id="nav-collapse">
          <ul class="navbar-nav m-auto text-uppercase">
            <li class="nav-item"><a :class="getLinkClass('/')" @click="$router.push('/')">{{ $t("navbar.home") }}</a></li>
            <li class="nav-item dropdown">
              <a class="nav-link btn dropdown-toggle" id="productDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ $t("navbar.products") }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="productDropdown">
                <li><a class="dropdown-item" @click="$router.push('/products/dentalaide')" role="button">{{ $t("navbar.dentalaide") }}</a></li>
                <li><a class="dropdown-item disabled" role="button">{{ $t("navbar.prognosis") }}</a></li>
                <li><a class="dropdown-item disabled" role="button">{{ $t("navbar.microbioma") }}</a></li>
              </ul>
            </li>
            <li class="nav-item"><a :class="getLinkClass('/team')" @click="$router.push('/team')">{{ $t("navbar.team") }}</a></li>
            <li class="nav-item"><a :class="getLinkClass('/application')" v-if="isLoggedIn" @click="$router.push('/application')">{{ $t("navbar.application") }}</a></li>
          </ul>
          <ul class="navbar-nav">
            <li class="nav-item dropdown" v-if="isLoggedIn">
              <a class="nav-link dropdown-toggle" id="userNavbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{ user.email }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="userNavbarDropdown">
                <li><a class="dropdown-item"  @click="$router.push('/profile')" role="button">{{ $t("navbar.profile") }}</a></li>
                <li><a class="dropdown-item" @click="logout" role="button">{{ $t("navbar.log-out") }}</a></li>
              </ul>
            </li>
            <li v-else>
              <ul class="navbar-nav">
                <li class="nav-item"><a class="nav-link no-hover text-white btn login-pill px-3 me-lg-3" @click="$router.push('/login')" role="button">{{ $t("navbar.log-in") }}</a></li>
              </ul>
            </li>
            <LocaleSwitch />
          </ul>
        </div>
      </div>
    </nav>

  </div>
</template>

<script>
import LocaleSwitch from '@/components/LocaleSwitch'
import {Auth} from "aws-amplify";

export default {
  name: 'NavBar',
  props: {
    type: String,
    variant: String,
  },
  components: {
    LocaleSwitch,
  },
  data: () => ({
  }),
  methods: {
    async logout () {
      Auth.signOut().catch((e) => {
        console.log(e)
      })
    },
    has_role(role) {
      return this.isLoggedIn && role === role
    },
    getLinkClass(link_href) {
      let css_class = "nav-link btn"
      if(this.$router.currentRoute.value.fullPath === link_href)
        css_class = css_class + " active"
      return css_class
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    user() {
      return this.$store.getters.user
    }
  }
}
</script>

<style scoped>
.navbar {
  height: 100px;
  font-size: 15px;
}

.navbar-light .nav-link {
  color: rgba(0, 0, 0, 1);
}

@media (max-width: 991px) {
  .navbar-light .navbar-collapse {
    background: white;
    padding: 20px;
  }

  .navbar-dark .navbar-collapse {
    background: #343a40 !important;
    padding: 20px;
  }
}

.nav-item .active {
  color: #0097b0 !important;
}

.nav-link:hover {
  color: #0097b0 !important;
}

.no-hover:hover {
  color: white !important;
}

.bg-light {
  background-color: #fffc !important;
}

.logo img{
  width: 200px;
  max-width: 75%;
}

.login-pill {
  background-color: #0097b0;
  color: white;
  border-radius: 20px;
}
</style>
